import React, { useState } from 'react';
import { StyleSheet, View, TextInput, TouchableOpacity, Text, Modal } from 'react-native';
import CalendarPicker from 'react-native-calendar-picker';

export function Reserver() {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [number, setNumber] = useState('');
  const [date, setDate] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleSubmit = () => {
    const endpointUrl = `https://formsubmit.co/jeremrichard20@gmail.com`;
    const formData = new FormData();
    formData.append('name', name);
    formData.append('phone', phone);
    formData.append('number', number);
    formData.append('date', date);
    fetch(endpointUrl, {
      method: 'POST',
      body: formData,
    });
    setShowModal(true);
  };

  return (
    <View style={styles.container}>
      <Text style={styles.heading}>Reserver</Text>
      <TextInput
        placeholder="Name"
        style={styles.input}
        value={name}
        onChangeText={setName}
      />
      <TextInput
        placeholder="Telephone"
        style={styles.input}
        value={phone}
        onChangeText={setPhone}
      />
      <TextInput
        placeholder="Nombre de couvert"
        style={[styles.input]}
        value={number}
        onChangeText={setNumber}
      />
      <Text style={styles.label}>Select a date:</Text>
      <View style={styles.dateContainer}>
        <CalendarPicker
          onDateChange={handleDateChange}
          selectedStartDate={selectedDate}
          minDate={new Date()}
          allowRangeSelection={false}
          width={300}
          height={300}
        />
      </View>
      <TouchableOpacity style={styles.button} onPress={handleSubmit}>
        <Text style={styles.buttonText}>Send</Text>
      </TouchableOpacity>
      <Modal visible={showModal} animationType="fade" transparent={true}>
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <Text style={styles.modalText}>Your message has been submitted successfully!</Text>
            <TouchableOpacity style={styles.modalButton} onPress={() => setShowModal(false)}>
              <Text style={styles.modalButtonText}>OK</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingHorizontal: 20,
        paddingVertical: 40,
        alignItems:'center',
    },
    heading: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 20,
    },
    input: {
        height: 40,
        width: '50%',
        borderColor: 'gray',
        borderWidth: 1,
        paddingHorizontal: 10,
        marginBottom: 10,
    },
    button: {
        backgroundColor: '#2196F3',
        borderRadius: 5,
        alignSelf: 'center',
        maxWidth: '100%',
        marginTop: 20,
        width: 100,
        marginBottom: 40,
    },
    buttonText: {
        color: '#fff',
        textAlign: 'center',
        paddingVertical: 10,
        fontSize: 16,
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
        backgroundColor: '#fff',
        padding: 20,
        borderRadius: 10,
        alignItems: 'center',
    },
    modalText: {
        fontSize: 18,
        marginBottom: 20,
        textAlign: 'center',
    },
    modalButton: {
        backgroundColor: '#2196F3',
        padding: 10,
        borderRadius: 5,
    },
    modalButtonText: {
        color: '#fff',
        textAlign: 'center',
    },
    label: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 5,
    },
    dateContainer:{
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    calendar: {
        width: '100%',
        alignSelf: 'center',
        marginTop: 10,
        marginBottom: 20,
    },
});
