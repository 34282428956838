import React from 'react';
import { StyleSheet, View, Text, TouchableOpacity, Linking, Dimensions,Image } from 'react-native';


const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export function ContactPhone() {
    const handlePhonePress = () => {
        Linking.openURL('tel:+1234567890');
    };

    const handleEmailPress = () => {
        Linking.openURL('mailto:example@gmail.com');
    };

    return (
        <View style={styles.container}>
            <TouchableOpacity style={styles.contactItem} onPress={handlePhonePress}>
            <Image style={styles.imageLogo} source={require('./src/logoTelephone.png')} />
                <Text style={styles.label}>Phone:</Text>
                <Text style={styles.value}>+1234567890</Text>
            </TouchableOpacity>


            <TouchableOpacity style={styles.contactItem} onPress={handleEmailPress}>
            <Image style={styles.imageLogo2} source={require('./src/logoEmail.png')} />
                <Text style={styles.label}>Email:</Text>
                <Text style={styles.value}>example@gmail.com</Text>
            </TouchableOpacity>
        </View>
    );
}

const styles = StyleSheet.create({
    imageLogo:{
        width: 100,
        height: 100,

    },
    imageLogo2:{
        width: 70,
        height: 70,

    },
    container: {
        flex: 1,
        alignItems: 'center',
        paddingVertical: 20,
        paddingHorizontal: 10,
    },
    heading: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 20,
    },
    contactItem: {
        borderWidth: 1,
        borderRadius: 10,
        paddingHorizontal: 5,
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 10,
        maxWidth: '80%',
    },
    label: {
        fontSize: 16,
        fontWeight: 'bold',
        marginRight: 10,
    },
    value: {
        fontSize: 16,
    },
});

