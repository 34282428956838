import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Image, Text, Dimensions } from 'react-native';


const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const IMAGES = [
  require('./src/interieur.jpg'),
  require('./src/exterieur.jpg'),
  require('./src/interieur4.PNG'),
];



export function Localisation() {

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((currentImageIndex + 1) % IMAGES.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [currentImageIndex]);

  const currentImage = IMAGES[currentImageIndex];

  return (
    <View style={styles.container}>
      <View style={styles.title}>
        <Text style={styles.textTitle} >Localisation</Text>
      </View>
      <View style={styles.containerFlex}>
        <View style={styles.containerLoca}>
          <Image style={styles.img}
            source={require('./src/localisation.PNG')}
          />
        </View>
        <View style={styles.containerLoca}>
          <Image source={currentImage} style={styles.img} />
        </View>
      </View>
    </View>
  )
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  containerFlex: {
    flexDirection: 'row',
    justifyContent: 'center',

  },
  containerLoca: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 10,
    paddingHorizontal: 50,
    height: windowHeight * 0.5,
    width: windowWidth* 0.4 ,
  },
  img: {
    height: '100%',
    width: '100%',
    borderRadius: 10,
  },
  title: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 100,

  },
  textTitle: {
    fontSize: 50,
  }
});


