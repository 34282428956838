import React from 'react';
import { StyleSheet, View, Text, TouchableOpacity, Linking, Dimensions } from 'react-native';
import { Card } from '@rneui/themed';1


const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export function Contact2() {
    const handlePhonePress = () => {
        Linking.openURL('tel:+1234567890');
    };

    const handleEmailPress = () => {
        Linking.openURL('mailto:example@gmail.com');
    };

    return (
        <View style={styles.containerimg}>
            <View style={styles.contCard}>
                <Card>
                    <Card.Title>Contact Us</Card.Title>
                    <Card.Divider />
                    <Card.Image
                        style={{
                            padding: 0,
                            marginHorizontal: 20,
                            height: 150,
                            width: 200,
                            alignItems:'center',
                        }}
                        source={
                            require('./src/logoTelephone.png')
                        }
                    />
                    <Text style={{ margin: 20 }}>
                        <TouchableOpacity style={styles.contactItem} onPress={handlePhonePress}>
                            <Text style={styles.label}>Phone:</Text>
                            <Text style={styles.value}>+1234567890</Text>
                        </TouchableOpacity>
                    </Text>

                </Card>
            </View>
            <View style={styles.contCard}>
                <Card>
                    <Card.Title>Email:</Card.Title>
                    <Card.Divider />
                    <Card.Image
                        style={{ padding: 0 }}
                        source={
                            require('./src/logoEmail.png')
                        }
                    />
                    <Text style={{ margin: 20 }}>
                        <TouchableOpacity style={styles.contactItem} onPress={handleEmailPress}>
                            <Text style={styles.label}>Email:</Text>
                            <Text style={styles.value}>example@gmail.com</Text>
                        </TouchableOpacity>
                    </Text>

                </Card>
            </View>
        </View>

    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        paddingVertical: 20,
        paddingHorizontal: 10,
    },
    heading: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 20,
    },
    contactItem: {
        
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 10,
        maxWidth: '80%',
    },
    label: {
        fontSize: 16,
        fontWeight: 'bold',
        marginRight: 10,
    },
    value: {
        fontSize: 16,
    },
    containerimg: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 10,
    },
    img: {
        marginTop: 50,
        height: 300,
        width: 300,
    },
    img2: {
        marginTop: 50,
        height: 450,
        width: 300,
    },
    contCard: {
        width: '20%',
        borderRadius: 15,
        height: windowHeight * 0.3,
        width: windowWidth * 0.2,
        paddingHorizontal: 5,

    },
});

