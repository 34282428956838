import React from 'react';
import { View, StyleSheet, Image,Dimensions } from 'react-native';
import { Text, Card} from '@rneui/themed';


const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export function HomeScreen() {
  return (
    <View style={styles.container}>
      <View style={styles.containerimg}>
        <Image style={styles.img}
          source={require('./src/chez-felix.png')}
        />
        <Image style={styles.img2}
          source={require('./src/felixanimation2.gif')}
        />
      </View>
      <View style={styles.container}>
        <View style={styles.containerimg}>
          <View style={styles.contCard}>
            <Card style={styles.card}>
              <Card.Title>Très bonne adresse</Card.Title>
              <Card.Divider />
              <Card.Image
                style={{ padding: 0 }}
                source={
                  require('./src/starters.jpg')
                }
              />
              <Text style={{ margin: 11 }}>
                "Mets de qualité, préparés maison et très goûteux. "
                Erichermalle
                Voir les avis mentionnant « Très bonne adresse »
              </Text>

            </Card>
          </View>
          <View style={styles.contCard}>
            <Card style={styles.card}>
              <Card.Title>Pour un dinée en terasse</Card.Title>
              <Card.Divider />
              <Card.Image
                style={{ padding: 0 }}
                source={
                  require('./src/exterieur.jpg')
                }
              />
              <Text style={{ margin: 20 }}>
                "Superbe terrasse au coeur d'un jardin."
                VinceJ83
                Voir les avis mentionnant « terrasse »
              </Text>

            </Card>
          </View>
          <View style={styles.contCard}>
            <Card style={styles.card}>
              <Card.Title>Excellentissime</Card.Title>
              <Card.Divider />
              <Card.Image
                style={{ padding: 0 }}
                source={
                  require('./src/interieur.jpg')
                }
              />
              <Text style={{ margin: 20 }}>
                accueil chaleureux, service rapide et plats délicieux "
                Calista D
                Voir les avis mentionnant « accueil »
              </Text>

            </Card>
          </View>
        </View>
      </View>
    </View>
  );
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  containerimg: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 10,
    maxHeight: windowHeight*0.4,
  },
  img: {
    marginTop: 50,
    height: windowHeight * 0.3,
    width: windowWidth * 0.2,
  },
  img2: {
    marginTop: 50,
    height: windowHeight * 0.3,
    width: windowWidth * 0.1,
  },
  contCard: {
    height: windowHeight * 0.3,
    width: windowWidth * 0.2,
  },
  card:{
    width: 100,
  }
});
