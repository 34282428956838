import { Navbar } from './pages/navbar'
import { HomeScreen } from './pages/homescreen';
import { StyleSheet, View, Dimensions, Text } from 'react-native';
import { Menu } from './pages/Menu';
import { Localisation } from './pages/localisation';
import { Avis } from './pages/avis';
import { Contact2 } from './pages/contact2';
import { Reserver } from './pages/reserver';
import { HomeScreenPhone } from './pages/homesreenPhone';
import { NavbarPhone } from './pages/navbarPhone';
import { MenuPhone } from './pages/menuPhone';
import { LocalisationPhone } from './pages/localisationPhone';
import { ContactPhone } from './pages/contactPhone';


const windowWidth = Dimensions.get('window').width;

export default function App() {
  const renderContent = () => {
    if (windowWidth >= 768) {
      return (
        <>
          {
            <View style={styles.container}>
              <View style={styles.container}>
                <Navbar />
                <HomeScreen />
              </View>
              <View style={styles.container2}>
                <Menu />
              </View>
              <View style={styles.container2}>
                <Avis />
              </View>
              <View style={styles.container2}>
                <Localisation />
              </View>
              <View style={styles.container2}>
                <Reserver />
              </View>
              <View style={styles.container2}>
                <Contact2 />
              </View>
            </View>
          }
        </>
      );
    } else {
      return (
        <>
          {
            <View>
              <View style={styles.container}>
                <NavbarPhone />
              </View>
              <View style={styles.container}>
                <HomeScreenPhone />
              </View>
              <View>
                <MenuPhone />
              </View>
              <View>
                <LocalisationPhone />
              </View>
              <View>
                <Reserver />
              </View>
              <View>
                <ContactPhone />
              </View>
            </View>
          }
        </>
      );
    }
  };
  return (
    <View View style={styles.cont}>
      {renderContent()}
    </View>
  );
}
const styles = StyleSheet.create({
  cont: {
    flex: 1,
  },
  container: {

  },
  container2: {
    marginTop: 150,


  },
});

