import React from 'react';
import { View, StyleSheet, Image, Dimensions } from 'react-native';
import { Text, Card } from '@rneui/themed';


const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export function HomeScreenPhone() {
    return (
        <View style={styles.container}>
            <View style={styles.containerimg}>
                <Image style={styles.img}
                    source={require('./src/chez-felix.png')}
                />
                <Image style={styles.img2}
                    source={require('./src/felixanimation2.gif')}
                />
            </View>
            <View >
                <View style={styles.contCard}>
                    <Card>
                        <Card.Title>Très bonne adresse</Card.Title>
                        <Card.Divider />
                        <Card.Image
                            style={{ padding: 0 }}
                            source={
                                require('./src/starters.jpg')
                            }
                        />
                        <Text style={{ margin: 20 }}>
                            "Mets de qualité, préparés maison et très goûteux. "
                            Erichermalle
                            Voir les avis mentionnant « Très bonne adresse »
                        </Text>

                    </Card>
                </View>
                <View style={styles.contCard}>
                    <Card >
                        <Card.Title>Pour un dinée en terasse</Card.Title>
                        <Card.Divider />
                        <Card.Image
                            style={{ padding: 0 }}
                            source={
                                require('./src/exterieur.jpg')
                            }
                        />
                        <Text style={{ margin: 20 }}>
                            "Superbe terrasse au coeur d'un jardin."
                            VinceJ83
                            Voir les avis mentionnant « terrasse »
                        </Text>

                    </Card>
                </View>
                <View style={styles.contCard}>
                    <Card>
                        <Card.Title>Excellentissime</Card.Title>
                        <Card.Divider />
                        <Card.Image
                            style={{ padding: 0 }}
                            source={
                                require('./src/interieur.jpg')
                            }
                        />
                        <Text style={{ margin: 20 }}>
                            accueil chaleureux, service rapide et plats délicieux "
                            Calista D
                            Voir les avis mentionnant « accueil »
                        </Text>

                    </Card>
                </View>
            </View>
        </View>
    );
}


const styles = StyleSheet.create({
    container: {
    },
    containerimg: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 10,
    },
    contaierCard: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    img: {
        marginTop: 50,
        height: 150,
        width: 150,
    },
    img2: {
        marginTop: 50,
        height: 200,
        width: 150,
    },
    contCard: {
        width: '100%',
    },
});
